import React, { useEffect } from 'react';
// import { graphql, useStaticQuery } from 'gatsby';

import Styles from './chat.styles';

export const ChatIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
    <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
  </svg>
);

export const CallIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
  </svg>
);

export default function Chat({ children }) {
  // const data = useStaticQuery(graphql`
  //   query ChatQuery {
  //     wp {
  //       globalContent {
  //         globalContact {
  //           phone
  //         }
  //       }
  //     }
  //   }
  // `);

  const startChat = () => window.liveagent.startChat('57340000000XZAM');

  const wait = (count) => {
    // if live agent not available after 10 seconds, give up
    if (count > 9) {
      return;
    }
    if (!!window && !!window.liveagent) {
      if (!window._laq) {
        window._laq = [];
      }
      window._laq.push(function () {
        window.liveagent.showWhenOnline(
          'liveagent_button_online_57340000000XZAM',
          document.getElementById('liveagent_button_online_57340000000XZAM')
        );
        window.liveagent.showWhenOffline(
          'liveagent_button_offline_57340000000XZAM',
          document.getElementById('liveagent_button_offline_57340000000XZAM')
        );
      });
      window.liveagent.init(
        'https://d.la2-c2-iad.salesforceliveagent.com/chat',
        '5721W000000PBAu',
        '00D40000000N3Wk'
      );
    } else {
      setTimeout(() => wait(++count), 1000);
    }
  };

  useEffect(() => {
    // Mount unmount
    wait(0);

    return () => {
      window.liveagent.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Styles>
        <button
          className={`btn btn-primary btn-chat`}
          id={`liveagent_button_online_57340000000XZAM`}
          style={{ display: 'none', border: '0px none', cursor: 'pointer' }}
          onClick={startChat}
          aria-label="Chat with our care specialists now."
        >
          <ChatIcon />
          Chat With A Care Specialist Now
        </button>

        <a
          href={`tel:(866) 209-6600`}
          className={`btn btn-primary btn-call`}
          id={`liveagent_button_offline_57340000000XZAM`}
          style={{ display: 'none', border: '0px none', cursor: 'pointer' }}
        >
          <CallIcon />
          Call A Care Specialist Now
        </a>
      </Styles>
      <>{children}</>
    </>
  );
}
