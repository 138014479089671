import styled from '@emotion/styled';
import rhythm from '../../utils/rhythm';
import colors from '../../styles/modules/colors.module.scss';
import utilities from '../../styles/modules/utilities.module.scss';

const Styles = styled.aside({
  position: 'fixed',
  bottom: rhythm(1),
  right: rhythm(1),
  zIndex: 10,

  'a, button': {
    height: rhythm(2),
    padding: rhythm(1 / 2),
    lineHeight: rhythm(1),
    boxShadow: utilities.boxShadow,
  },

  svg: {
    height: rhythm(1),
    width: rhythm(1),
    marginRight: rhythm(1 / 4),
    color: colors.white,
  },
});

export default Styles;
