// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-covid-19-response-jsx": () => import("./../../../src/pages/covid-19-response.jsx" /* webpackChunkName: "component---src-pages-covid-19-response-jsx" */),
  "component---src-pages-how-we-treat-jsx": () => import("./../../../src/pages/how-we-treat.jsx" /* webpackChunkName: "component---src-pages-how-we-treat-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-insurance-jsx": () => import("./../../../src/pages/insurance.jsx" /* webpackChunkName: "component---src-pages-insurance-jsx" */),
  "component---src-pages-our-location-index-jsx": () => import("./../../../src/pages/our-location/index.jsx" /* webpackChunkName: "component---src-pages-our-location-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-what-we-treat-index-jsx": () => import("./../../../src/pages/what-we-treat/index.jsx" /* webpackChunkName: "component---src-pages-what-we-treat-index-jsx" */),
  "component---src-pages-who-we-are-jsx": () => import("./../../../src/pages/who-we-are.jsx" /* webpackChunkName: "component---src-pages-who-we-are-jsx" */)
}

